<script>
import { required, email } from "vuelidate/lib/validators";
import FlagButton from '@/components/FlagButton.vue';
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {logoHelper} from "@/helpers/logo-helper";
import InformationPanelRender from "@/components/account/information-panel-render.vue";

export default {
  components: {InformationPanelRender, FlagButton},
  data() {
    return {
      email: "",
      submitted: false,

      error: null,
      isResetError: false
    };
  },

  validations: {
    email: { required, email }
  },

  created() {
    document.body.classList.add("auth-body-bg");
  },

  methods: {
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify({
        email: this.email,
        environmentType: process.env.VUE_APP_ECAT_ENVIRONMENT_TYPE
      });

      axios.post(`/user/forgot-password`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.email = "";
        Swal.fire("Sukces!", "Na adres E-mail został wysłany link do zmiany hasła!", "success");
        this.submitted = false;
      }).catch((error) => {
        this.submitted = false;
        this.isResetError = true;
        this.error = errorCatcher.catchErrors(error);
      })
    }
  },

  computed: {
    logoHelper() {
      return logoHelper
    },

    getImageSize() {
      let screenWidth = screen.width;
      let width = 60;
      if (screenWidth > 576 && screenWidth <= 992) {
        width = 35;
      }

      return width;
    }
  },

};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <FlagButton :drop-left="true"></FlagButton>
      <button @click="$store.dispatch('theme/switchTheme').then(() => $store.dispatch('theme/updateTheme'))" type="button" class="btn btn-sm px-3 font-size-24 header-item">
        <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'" style="color: #ffffff;"></i>
      </button>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img :src="logoHelper.logo()" :height="getImageSize" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{ $t('forgot-password.title')}}</h4>
                        <p class="text-muted">{{ $t('forgot-password.subtitle') }}</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="isResetError"
                          class="mb-4"
                          variant="danger"
                          dismissible
                        >{{error}}</b-alert>

                        <form class="form-horizontal" @submit.prevent="tryToReset">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              v-model="email"
                              type="email"
                              class="form-control"
                              id="email"
                              :placeholder="$t('message.type', { 'type': 'email'})"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">{{ $t('message.required') }}</span>
                              <span v-if="!$v.email.email">{{ $t('message.email-error') }}</span>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light w-100"
                              type="submit"
                            >{{  $t('forgot-password.reset') }}</button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          {{  $t('login.no-account') }}
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                          >{{ $t('login.register') }}</router-link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <information-panel-render/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>